import React, { useEffect, useState } from 'react';
import { JoinUsNowModal } from '../components/JoinUsNowModal';
import { Page1 } from './homeScreens/Page1';
import { Page2 } from './homeScreens/Page2';
import { Page3 } from './homeScreens/Page3';
import { Page4 } from './homeScreens/Page4';
import { Page5 } from './homeScreens/Page5';
import { Page6 } from './homeScreens/Page6';
import { Page7 } from './homeScreens/Page7';
import { Footer } from '../components/Footer';

const HomePage: React.FC = () => {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };



    const [modalTriggered, setModalTriggered] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            // Convert 150vh to pixels
            const scrollThreshold = window.innerHeight * 1;

            if (window.scrollY > scrollThreshold && !modalTriggered) {
                setOpen(true);
                setModalTriggered(true); // Prevent further triggers
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [modalTriggered]);




    return (
        <>
            <Page1 setOpen={setOpen} />
            <Page2 setOpen={setOpen} />
            <Page3 />
            <Page4 setOpen={setOpen} />
            <Page5 />
            <Page6 />
            {/* <Page7 /> */} {/**temporary hide this */}
            <Footer />
            <JoinUsNowModal
                open={open}
                handleClose={handleClose}
                setOpen={setOpen}
            />


        </>
    );
};

export default HomePage;
