import { Box, Typography, Grid, Stack, Card, keyframes, Tab, Tabs, SxProps, Theme } from '@mui/material';
import productBg from '../../assets/background_brown.png';
import packaging from '../../assets/Packaging.webp';
import productImage2 from '../../assets/Products_02.jpg';

import React from 'react';

export const Page2 = (props: { setOpen: (arg0: boolean) => void; }) => {
    const moveTextSeamless = keyframes`
  0% {
    transform: translateX(150%);
  }
  100% {
    transform: translateX(-100%);
  }
`;


    const [value, setValue] = React.useState(0);

    const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
        console.log(newValue);
        setValue(newValue);
    };

    interface CustomTabProps {
        label: string;
        selected?: boolean; // Optional, to indicate if the tab is selected
        sx?: SxProps<Theme>; // To allow additional styling
    }

    const CustomTab: React.FC<CustomTabProps> = ({ label, selected, ...props }) => (

        <Tab
            label={label}
            sx={{
                textTransform: 'uppercase',
                fontFamily: 'Sofia Sans',
                flex: 1,
                fontSize: {
                    md: '18px',
                    xs: '12px'
                },
                fontWeight: 400,
                lineHeight: '21.6px',
                textAlign: 'center',
                borderRadius: '20px',
                // border: '1px solid rgba(171, 141, 112, 0.5)', // Border outline for each tab
                marginRight: '2px', // Add margin between tabs
                backgroundColor: selected ? 'rgba(171, 141, 112, 1)' : 'transparent', // Background color for selected tab
                color: selected ? '#FFFFFF' : 'rgba(171, 141, 112, 1)', // Text color for selected and non-selected tabs
                '&:hover': {
                    backgroundColor: selected ? 'rgba(171, 141, 112, 1)' : 'rgba(171, 141, 112, 0.1)', // Background color on hover
                },
            }}
            {...props}
        />
    );

    return (
        <Box
            id="product"
            sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'flex-end', // Aligns the content at the bottom
                justifyContent: 'center',
                minHeight: {
                    xs: '160vh',  // For extra-small screens
                    sm: '70vh',  // For small screens
                    md: '100vh',  // For medium screens
                    lg: '100vh',  // For large screens
                },
                paddingTop: '100px',
                overflow: 'hidden', // Ensure background image doesn't overflow
            }}
        >
            <img
                loading="lazy"
                src={productBg}
                alt="Product BG"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: -1, // Ensures the image stays behind the text
                }}
            />
            <Box
                sx={{
                    position: 'absolute', // Change from 'absolute' to 'relative'
                    justifyContent: 'center',
                    bottom: '100px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    zIndex: 3, // Ensure the card is above the background image
                }}
            >
                <Card
                    sx={{
                        position: 'relative', // Remove 'absolute' positioning
                        padding: '24px',
                        borderRadius: '12px',
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                        maxWidth: '800px',
                        margin: '0 auto', // Center the card
                        zIndex: 3
                    }}
                >

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Box
                                component="img"
                                src={value === 0 ? productImage2 : packaging} // Replace with your image source
                                alt="Product Image"
                                sx={{
                                    width: '100%',
                                    borderRadius: '12px',
                                    objectFit: 'cover',
                                    height: {
                                        sm: '500px',
                                        xs: '200px'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} display={'flex'} direction={'column'} justifyContent={'space-between'}>
                            <Stack>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    sx={{
                                        // display: 'flex',
                                        // justifyContent: 'space-between',
                                        border: '1px solid rgba(0, 0, 0, 0.12)', // Border outline for the tabs container
                                        borderRadius: '30px',
                                        padding: '10px',

                                    }}
                                    TabIndicatorProps={{
                                        style: {
                                            display: 'none'
                                        }
                                    }}
                                >
                                    <CustomTab label="WELLNESS WEAR" selected={value === 0} />
                                    <CustomTab label="WHAT'S INSIDE" selected={value === 1} />
                                </Tabs>
                                {value === 0 &&
                                    <Stack>
                                        <Typography
                                            variant="h2"
                                            sx={{
                                                fontFamily: 'Forum',
                                                fontSize: '56px',
                                                fontWeight: 400,
                                                lineHeight: '60px',
                                                textAlign: 'left',
                                                color: 'rgba(65, 69, 45, 1)',
                                                marginTop: '20px'
                                            }}
                                        >
                                            Wellness Set
                                        </Typography>
                                        {/* <Typography
                                        variant="h5"
                                        sx={{
                                            fontFamily: 'Sofia Sans',
                                            fontSize: '25px',
                                            fontWeight: 300,
                                            lineHeight: '30px',
                                            textAlign: 'left',
                                            marginBottom: '16px',
                                            color: 'rgba(44, 44, 44, 1)',
                                        }}
                                    >
                                        Available In Sizes S, M, L
                                    </Typography> */}
                                        {/* <Typography
                                            variant="body1"
                                            sx={{
                                                fontFamily: 'Sofia Sans',
                                                fontSize: '18px',
                                                fontWeight: 400,
                                                lineHeight: '21.6px',
                                                textAlign: 'left',
                                                color: 'rgba(108, 105, 85, 1)',
                                                marginBottom: '24px',
                                            }}
                                        >
                                            Designed for your convenience, our garments are washing machine
                                            and dryer-friendly, ensuring worry-free cleaning.
                                        </Typography> */}
                                        {/* <Typography
                                        variant="body2"
                                        sx={{
                                            fontFamily: 'Sofia Sans',
                                            fontSize: '20px',
                                            fontWeight: 700,
                                            lineHeight: '24px',
                                            textAlign: 'left',
                                            color: 'rgba(44, 44, 44, 1)',
                                        }}
                                    >
                                        Made with Vene Fabric:

                                    </Typography>
                                    <Typography sx={{
                                        fontFamily: 'Sofia Sans',
                                        fontSize: '18px',
                                        fontWeight: 400,
                                        lineHeight: '21.6px',
                                        textAlign: 'left',
                                        color: 'rgba(108, 105, 85, 1)'
                                    }}>
                                        <ul>
                                            <li>Lorem</li>
                                            <li>Ipsum</li>
                                            <li>Sit</li>
                                            <li>Dolor</li>
                                        </ul>
                                    </Typography> */}

                                    </Stack>
                                }
                                {value === 1 &&
                                    <Stack>
                                        <Typography
                                            variant="h2"
                                            sx={{
                                                fontFamily: 'Forum',
                                                fontSize: '56px',
                                                fontWeight: 400,
                                                lineHeight: '60px',
                                                textAlign: 'left',
                                                color: 'rgba(65, 69, 45, 1)',
                                                marginTop: '20px'
                                            }}
                                        >
                                            Our Packaging
                                        </Typography>
                                        {/* <Typography
                                        variant="h5"
                                        sx={{
                                            fontFamily: 'Sofia Sans',
                                            fontSize: '25px',
                                            fontWeight: 300,
                                            lineHeight: '30px',
                                            textAlign: 'left',
                                            marginBottom: '16px',
                                            color: 'rgba(44, 44, 44, 1)',
                                        }}
                                    >
                                        Available in sizes S, M, L
                                    </Typography> */}
                                        {/* <Typography
                                            variant="body1"
                                            sx={{
                                                fontFamily: 'Sofia Sans',
                                                fontSize: '18px',
                                                fontWeight: 400,
                                                lineHeight: '21.6px',
                                                textAlign: 'left',
                                                color: 'rgba(108, 105, 85, 1)',
                                                marginBottom: '24px',
                                            }}
                                        >
                                            Designed for your convenience, our garments are washing machine and dryer-friendly, ensuring worry-free cleaning.
                                        </Typography> */}
                                        {/* <Typography
                                        variant="body2"
                                        sx={{
                                            fontFamily: 'Sofia Sans',
                                            fontSize: '20px',
                                            fontWeight: 700,
                                            lineHeight: '24px',
                                            textAlign: 'left',
                                            color: 'rgba(44, 44, 44, 1)',
                                        }}
                                    >
                                        Features

                                    </Typography>
                                    <Typography sx={{
                                        fontFamily: 'Sofia Sans',
                                        fontSize: '18px',
                                        fontWeight: 400,
                                        lineHeight: '21.6px',
                                        textAlign: 'left',
                                        color: 'rgba(108, 105, 85, 1)'
                                    }}>
                                        <ul>
                                            <li>Lorem</li>
                                            <li>Ipsum</li>
                                            <li>Sit</li>
                                            <li>Dolor</li>
                                        </ul>
                                    </Typography> */}

                                    </Stack>
                                }
                            </Stack>
                            <Typography sx={{
                                fontFamily: 'Sofia Sans',
                                fontSize: '18px',
                                fontWeight: 400,
                                lineHeight: '21.88px',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                textAlign: 'right',
                                color: 'rgba(171, 175, 140, 1)'
                            }}
                                onClick={() => {
                                    props.setOpen(true);
                                }}
                            >
                                PREORDER NOW
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
            <Stack direction={'row'}>
                <Typography
                    sx={{
                        fontFamily: 'Forum',
                        fontSize: '200px',
                        fontWeight: 400,
                        lineHeight: '180px',
                        textAlign: 'left',
                        color: 'rgba(238, 239, 232, 1)',
                        whiteSpace: 'nowrap',
                        animation: `${moveTextSeamless} 8s linear infinite`,
                        marginRight: '500px',
                        position: 'relative',
                        zIndex: 2 // Ensure it is above the card
                    }}
                >
                    Sneak Peek
                </Typography>
            </Stack>
        </Box>

    );
};