import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

import imgLogo from "../assets/logo.png";
import { JoinUsNowModal } from './JoinUsNowModal';
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';

const ResponsiveAppBar: React.FC = () => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [appBarBg, setAppBarBg] = React.useState<string>("rgba(255, 255, 255, 0)"); // Start with transparent
    const location = useLocation();

    const toggleDrawer = (open: boolean) => () => {
        setDrawerOpen(open);
    };
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        // Check if the path is "/"
        if (location.pathname === '/') {
            setAppBarBg("rgba(255, 255, 255, 0)");
        } else {
            setAppBarBg("rgba(255, 255, 255, 1)");
        }
    }, [location.pathname]); // Update when the path changes



    const handleScroll = () => {
        const homeSection = document.getElementById("home");
        if (!homeSection) return;

        const homeSectionBottom = homeSection.getBoundingClientRect().bottom;
        const appBarHeight = 64; // Adjust this if your AppBar height changes

        // Calculate the opacity based on the scroll position
        const opacity = Math.min(1, (appBarHeight - homeSectionBottom) / appBarHeight);

        // Set the AppBar background color with RGBA
        setAppBarBg(`rgba(255, 255, 255, ${opacity})`);
    };

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: appBarBg,
                    boxShadow: "none",
                    zIndex: 99,
                    // width: "100%",
                    transition: "background-color 0.3s ease",
                }}
            >
                <Box>
                    <Toolbar
                        disableGutters
                        sx={{ justifyContent: "space-between", padding: "20px" }}
                    >
                        <Box sx={{ display: { sm: "flex", md: "none" } }}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={toggleDrawer(true)}
                                sx={{ fontSize: '50px', color: '#abaf8c' }} // Adjust fontSize to make it thicker
                            >
                                <MenuIcon sx={{ fontSize: '30px' }} />
                            </IconButton>
                        </Box>
                        <Link href="/#home" sx={{ display: { md: "flex" }, alignItems: "center" }}>
                            <img
                                src={imgLogo}
                                alt="logo"
                                loading="lazy"
                                style={{ objectFit: "contain", width: "150px" }}
                            />
                        </Link>
                        <Box
                            sx={{
                                display: { xs: "none", sm: "flex", md: 'none' },
                                alignItems: "center",
                                gap: "20px",
                            }}
                        >
                            <Button

                                variant="contained"
                                sx={{
                                    backgroundColor: "#abaf8c",
                                    color: "#fff",
                                    borderRadius: "60px",
                                    padding: "10px 15px",
                                    "&:hover": { backgroundColor: "#8d9275" },
                                }}
                                onClick={() => {
                                    setOpen(true);
                                }}
                            >
                                Join the Wait
                            </Button>
                        </Box>

                        <Box
                            sx={{
                                display: { xs: "none", md: "flex" },
                                alignItems: "center",
                                gap: "20px",
                            }}
                        >
                            <Link
                                href="/"
                                color="#41452d"
                                sx={{
                                    textDecoration: "none",
                                    padding: "10px",
                                    transition: "color 0.3s",
                                    "&:hover": { color: "#abaf8c" },
                                }}
                            >
                                Home
                            </Link>
                            <Link
                                href="/about"
                                color="#41452d"
                                sx={{
                                    textDecoration: "none",
                                    padding: "10px",
                                    transition: "color 0.3s",
                                    "&:hover": { color: "#abaf8c" },
                                }}
                            >
                                About Us
                            </Link>
                            <Link
                                href="https://www.lavishamsia.com"
                                color="#41452d"
                                sx={{
                                    textDecoration: "none",
                                    padding: "10px",
                                    transition: "color 0.3s",
                                    "&:hover": { color: "#abaf8c" },
                                }}
                            >
                                Lavisha
                            </Link>
                            <Button

                                variant="contained"
                                sx={{
                                    backgroundColor: "#abaf8c",
                                    color: "#fff",
                                    borderRadius: "60px",
                                    padding: "10px 15px",
                                    "&:hover": { backgroundColor: "#8d9275" },
                                }}
                                onClick={() => {
                                    setOpen(true);
                                }}
                            >
                                Join the Wait
                            </Button>
                        </Box>
                    </Toolbar>
                </Box>
            </AppBar>

            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            >
                <Box
                    sx={{ width: 250, padding: "20px" }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
                        <img
                            src={imgLogo}
                            alt="logo"
                            loading="lazy"
                            style={{ objectFit: "contain", width: "150px" }}
                        />
                    </Box>
                    <Divider />
                    <List>
                        <ListItem
                            sx={{
                                fontFamily: 'Forum',
                                fontSize: '28px',
                                fontWeight: 400,
                                lineHeight: '25.2px',
                                textAlign: 'center',
                            }}
                            button component="a" href="/#home">
                            <Typography sx={{
                                fontFamily: 'Forum',
                                fontSize: '28px',
                                fontWeight: 400,
                                lineHeight: '25.2px',
                                textAlign: 'Left',
                            }}
                            >
                                Home
                            </Typography>
                        </ListItem>
                        <ListItem
                            sx={{
                                fontFamily: 'Forum',
                                fontSize: '28px',
                                fontWeight: 400,
                                lineHeight: '25.2px',
                                textAlign: 'center',
                            }}
                            button component="a" href="/about">
                            <Typography sx={{
                                fontFamily: 'Forum',
                                fontSize: '28px',
                                fontWeight: 400,
                                lineHeight: '25.2px',
                                textAlign: 'left',
                            }}
                            >
                                About Us
                            </Typography>
                        </ListItem>
                        <ListItem
                            sx={{
                                fontFamily: 'Forum',
                                fontSize: '28px',
                                fontWeight: 400,
                                lineHeight: '25.2px',
                                textAlign: 'left',
                            }}
                            button component="a" href="https://lavishamsia.com/">
                            <Typography sx={{
                                fontFamily: 'Forum',
                                fontSize: '28px',
                                fontWeight: 400,
                                lineHeight: '25.2px',
                                textAlign: 'center',
                            }}
                            >
                                Lavisha
                            </Typography>
                        </ListItem>
                    </List>
                    <Divider />
                    <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#abaf8c",
                                color: "#fff",
                                borderRadius: "60px",
                                padding: "10px 15px",
                                "&:hover": { backgroundColor: "#8d9275" },
                            }}
                            onClick={() => {
                                setOpen(true);
                            }}
                        >
                            Join the Wait
                        </Button>
                    </Box>
                </Box>
            </Drawer>

            <JoinUsNowModal
                open={open}
                handleClose={handleClose}
                setOpen={setOpen}
            />
        </>
    );
};

export default ResponsiveAppBar;
