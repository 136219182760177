import { Box, Typography, Grid, Stack } from '@mui/material';
import productBg from '../../assets/background_brown.png';
import productImage1 from '../../assets/Products_01.jpg';
import productImage2 from '../../assets/Products_02.jpg';
import productImage3 from '../../assets/Products_03.jpg';
import productImage4 from '../../assets/Products_04.jpg';
export const Page4 = (props: { setOpen: (arg0: boolean) => void; }) => {
    return (
        <Box
            id="product"
            sx={{
                position: "relative",
                justifyContent: 'center',
                display: 'flex'
            }}
        >
            <img
                loading="lazy"
                src={productBg}
                alt="Product BG"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                }}
            />
            <Box sx={{
                paddingTop: '100px',
                paddingBottom: '200px',
                flexDirection: 'column',
                alignItems: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',
                position: "relative",
                width: "100%",
            }}>
                <Typography
                    sx={{
                        fontFamily: "Forum, serif",
                        fontSize: "80px",
                        fontWeight: 400,
                        lineHeight: "72px",
                        textAlign: "center",
                        color: "#FFFFFF",
                    }}
                >
                    Wellness Wear Collection
                </Typography>
                <Typography
                    sx={{
                        fontFamily: "Sofia Sans, sans-serif",
                        fontSize: "20px",
                        fontWeight: 400,
                        lineHeight: "24px",
                        textAlign: "center",
                        color: "#FFFFFF",
                        marginTop: '20px',
                        padding: '20px'
                    }}
                >
                    {'Experience the perfect blend of fashion and function in our wellness apparel,'}
                    <br />
                    {'crafted from soft, breathable fabrics for all-day comfort.'}
                </Typography>
                <Grid
                    container
                    spacing={3}
                    sx={{
                        paddingTop: "50px",
                        paddingLeft: { xs: '10px', md: '30px', lg: '50px' },  // Smaller padding for smaller screens
                        paddingRight: { xs: '10px', md: '30px', lg: '50px' }, // Smaller padding for smaller screens
                        position: "relative",
                    }}
                >
                    <Grid item xs={12} sm={6} md={3}>
                        <Box
                            sx={{
                                position: 'relative',
                                backgroundColor: '#FFFFFF',
                                borderRadius: '30px',
                                overflow: 'hidden',

                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                '&:hover .overlay': {
                                    opacity: 1,
                                    transform: 'translateY(0)',
                                },
                            }}
                        >
                            <img
                                src={productImage1}
                                alt="Product 1"
                                style={{
                                    width: '-webkit-fill-available',
                                    height: '-webkit-fill-available',
                                    objectFit: 'cover',
                                }}
                            />
                            <Box
                                className="overlay"
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(171, 141, 112, 0.5)',
                                    flexDirection: 'column',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#FFFFFF',
                                    opacity: 0,
                                    transition: 'opacity 0.3s ease, transform 0.3s ease',
                                    transform: 'translateY(10%)',
                                    textAlign: 'center',
                                    padding: '20px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Forum, serif",
                                        fontSize: "56px",
                                        fontWeight: 400,
                                        lineHeight: "60px",
                                        textAlign: "center",
                                        margin: 'auto'
                                    }}
                                >
                                    Wellness Set
                                    Short
                                </Typography>
                                <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '100%' }}>
                                    <Typography
                                        sx={{
                                            fontFamily: "Sofia Sans, sans-serif",
                                            fontSize: "20px",
                                            fontWeight: 400,
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                    >
                                        Available in
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: "Sofia Sans, sans-serif",
                                            fontSize: "20px",
                                            fontWeight: 400,
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                    >
                                        S, M, L
                                    </Typography>
                                </Stack>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Box
                            sx={{
                                position: 'relative',
                                backgroundColor: '#FFFFFF',
                                borderRadius: '30px',
                                overflow: 'hidden',

                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                '&:hover .overlay': {
                                    opacity: 1,
                                    transform: 'translateY(0)',
                                },
                            }}
                        >
                            <img
                                src={productImage2}
                                alt="Product 2"
                                style={{
                                    width: '-webkit-fill-available',
                                    height: '-webkit-fill-available',
                                    objectFit: 'cover',
                                }}
                            />
                            <Box
                                className="overlay"
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(171, 141, 112, 0.5)',

                                    flexDirection: 'column',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#FFFFFF',
                                    opacity: 0,
                                    transition: 'opacity 0.3s ease, transform 0.3s ease',
                                    transform: 'translateY(10%)',
                                    textAlign: 'center',
                                    padding: '20px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Forum, serif",
                                        fontSize: "56px",
                                        fontWeight: 400,
                                        lineHeight: "60px",
                                        textAlign: "center",
                                        margin: 'auto'
                                    }}
                                >
                                    Wellness Pants
                                </Typography>
                                <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '100%' }}>
                                    <Typography
                                        sx={{
                                            fontFamily: "Sofia Sans, sans-serif",
                                            fontSize: "20px",
                                            fontWeight: 400,
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                    >
                                        Available in
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: "Sofia Sans, sans-serif",
                                            fontSize: "20px",
                                            fontWeight: 400,
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                    >
                                        S, M, L
                                    </Typography>
                                </Stack>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Box
                            sx={{
                                position: 'relative',
                                backgroundColor: '#FFFFFF',
                                borderRadius: '30px',
                                overflow: 'hidden',

                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                '&:hover .overlay': {
                                    opacity: 1,
                                    transform: 'translateY(0)',
                                },
                            }}
                        >
                            <img
                                src={productImage3}
                                alt="Product 3"
                                style={{
                                    width: '-webkit-fill-available',
                                    height: '-webkit-fill-available',
                                    objectFit: 'cover',
                                }}
                            />
                            <Box
                                className="overlay"
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(171, 141, 112, 0.5)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#FFFFFF',
                                    opacity: 0,
                                    transition: 'opacity 0.3s ease, transform 0.3s ease',
                                    transform: 'translateY(10%)',
                                    textAlign: 'center',
                                    padding: '20px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Forum, serif",
                                        fontSize: "53px",
                                        fontWeight: 400,
                                        lineHeight: "60px",
                                        textAlign: "center",
                                        margin: 'auto',
                                        lineBreak: 'anywhere'
                                    }}
                                >
                                    Racerback<br />Bra
                                </Typography>
                                <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '100%' }}>
                                    <Typography
                                        sx={{
                                            fontFamily: "Sofia Sans, sans-serif",
                                            fontSize: "20px",
                                            fontWeight: 400,
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                    >
                                        Available in
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: "Sofia Sans, sans-serif",
                                            fontSize: "20px",
                                            fontWeight: 400,
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                    >
                                        S, M, L
                                    </Typography>
                                </Stack>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Box
                            sx={{
                                position: 'relative',
                                backgroundColor: '#FFFFFF',
                                borderRadius: '30px',
                                overflow: 'hidden',

                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                '&:hover .overlay': {
                                    opacity: 1,
                                    transform: 'translateY(0)',
                                },
                            }}
                        >
                            <img
                                src={productImage4}
                                alt="Product 4"
                                style={{
                                    width: '-webkit-fill-available',
                                    height: '-webkit-fill-available',
                                    objectFit: 'cover',
                                }}
                            />
                            <Box
                                className="overlay"
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(171, 141, 112, 0.5)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#FFFFFF',
                                    opacity: 0,
                                    transition: 'opacity 0.3s ease, transform 0.3s ease',
                                    transform: 'translateY(10%)',
                                    textAlign: 'center',
                                    padding: '20px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Forum, serif",
                                        fontSize: "56px",
                                        fontWeight: 400,
                                        lineHeight: "60px",
                                        textAlign: "center",
                                        margin: 'auto'
                                    }}
                                >
                                    Wellness Set
                                    Long
                                </Typography>
                                <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '100%' }}>
                                    <Typography
                                        sx={{
                                            fontFamily: "Sofia Sans, sans-serif",
                                            fontSize: "20px",
                                            fontWeight: 400,
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                    >
                                        Available in
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: "Sofia Sans, sans-serif",
                                            fontSize: "20px",
                                            fontWeight: 400,
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                    >
                                        S, M, L
                                    </Typography>
                                </Stack>

                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Typography
                    sx={{
                        marginTop: '50px',
                        fontFamily: "Sofia Sans, sans-serif",
                        fontSize: "18px",
                        fontWeight: 400,
                        lineHeight: "21.88px",
                        color: "#EEEFE8",
                        textDecoration: "underline", // Adds underline
                        textAlign: 'center',
                        cursor: "pointer",
                    }}
                    onClick={() => props.setOpen(true)}
                >
                    JOIN THE WAIT
                </Typography>
            </Box>

        </Box>
    );
};