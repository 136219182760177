import React, { useState } from 'react';
import { ReactComponent as ArrowUp } from '../assets/Arrow_Up.svg';
import logoBackgrond from '../assets/LargeLogoFooter.svg';
import { ReactComponent as SocialFb } from '../assets/Social_FB.svg';
import { ReactComponent as SocialIg } from '../assets/Social_IG.svg';
import { Box, Typography, Button, TextField, Grid, Stack, Link, CircularProgress, Alert } from '@mui/material';
import imgLogo from "../assets/logo.png";
import { ArrowForward } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';

export const Footer: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [alertVisible, setAlertVisible] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setEmail(value);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setError(!emailRegex.test(value));
    };

    const handleSubmit = () => {
        setSubmitted(true); // Mark the form as submitted
        if (error) return; // Prevent submission if there is an error

        setLoading(true);
        const formData = new FormData();
        formData.append('email', email);

        fetch("https://script.google.com/macros/s/AKfycbzWic5SfdosPzd5_Uid8FaU8lxjxGpFKkbWEmY9EO76rk7HsQtJMR0JZPv5Gz7DIoN2Bg/exec", {
            method: 'POST',
            body: formData,
        })
            .then(res => res.json())
            .then(data => {
                setLoading(false);
                setAlertVisible(true);
                setTimeout(() => {
                    setAlertVisible(false);
                    setEmail('');
                    setSubmitted(false);
                }, 2000); // Wait 2 seconds before clearing the form
            })
            .catch(err => {
                setLoading(false);
                console.log(err);
            });
    };
    const handleLavishaRedirect = () => {
        window.location.href = "https://www.lavishamsia.com";
    };

    return (
        <Box
            sx={{
                backgroundColor: 'rgba(171, 175, 140, 1)', // Use the greenish color from the image
                color: 'rgba(44, 35, 29, 1)', // Dark text color
                padding: '50px 20px',
                position: 'relative',
                overflow: 'clip',
            }}
        >
            {/* Main Content */}
            <Grid container spacing={4} justifyContent="space-between">
                {/* Left - Leaf Icon */}
                <Grid item xs={6} sm={5}>
                    <Box
                        component="img"
                        src={logoBackgrond}
                        alt="Leaf Icon"
                        sx={{
                            height: '100%',
                            width: '100%',
                            transform: 'translateX(-10%)',
                        }}
                    />
                </Grid>

                {/* Right - Subscription and Info */}
                <Grid item xs={12} sm={7} sx={{
                    paddingRight: {
                        sx: 'none',
                        sm: '20px',
                    }
                }}>
                    <Box sx={{ textAlign: 'left' }}>
                        <Typography
                            sx={{
                                fontFamily: 'Forum, sans-serif',
                                fontSize: '56px',
                                fontWeight: 400,
                                lineHeight: '60px',
                                textAlign: 'left',
                            }}
                        >
                            Join the Wait
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginTop: '20px',
                            }}
                        >
                            <TextField
                                placeholder="Your Email Address*"
                                variant="standard"
                                value={email}
                                onChange={handleEmailChange}
                                error={error && submitted} // Show error text only if submitted
                                helperText={error && submitted ? 'Oops, this email doesn’t look right.' : ''}
                                sx={{
                                    width: '100%',
                                    marginRight: '10px',
                                    input: { color: '#333' }, // Text color inside the input
                                }}
                            />
                            <Button
                                variant="text"
                                onClick={handleSubmit}
                                disabled={error || loading}
                                sx={{ minWidth: 'auto', color: '#333' }}
                            >
                                {loading ? <CircularProgress size={24} /> : <ArrowForward sx={{ fontSize: '30px' }} />}
                            </Button>
                        </Box>

                        <Grid container spacing={4} sx={{ marginTop: '40px', textAlign: 'left' }}>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    sx={{
                                        fontFamily: 'Forum, sans-serif',
                                        fontSize: '36px',
                                        fontWeight: 400,
                                        lineHeight: '39.74px',
                                        textAlign: 'left',
                                    }}
                                >
                                    Address
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Sofia Sans, sans-serif',
                                        fontSize: '18px',
                                        fontWeight: 400,
                                        lineHeight: '21.6px',
                                        textAlign: 'left',
                                        color: 'rgba(108, 105, 85, 1)',
                                        marginTop: '20px'
                                    }}
                                >
                                    08-12 Millerz Square, No 357,
                                    <br />
                                    Jalan Old Klang Road,
                                    <br />
                                    58000 Kuala Lumpur, Malaysia.
                                </Typography>

                                <Typography
                                    sx={{
                                        fontFamily: 'Forum, sans-serif',
                                        fontSize: '36px',
                                        fontWeight: 400,
                                        lineHeight: '39.74px',
                                        textAlign: 'left',
                                        marginTop: '30px'
                                    }}
                                >
                                    Telephone
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Sofia Sans, sans-serif',
                                        fontSize: '18px',
                                        fontWeight: 400,
                                        lineHeight: '21.6px',
                                        textAlign: 'left',
                                        color: 'rgba(108, 105, 85, 1)',
                                        marginTop: '20px'
                                    }}
                                >
                                    +603-8684 2416
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Sofia Sans, sans-serif',
                                        fontSize: '18px',
                                        fontWeight: 400,
                                        lineHeight: '21.6px',
                                        textAlign: 'left',
                                        color: 'rgba(108, 105, 85, 1)',
                                        marginTop: '10px'
                                    }}
                                >
                                    +6012-512 4162
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
                                <Stack>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Forum, sans-serif',
                                            fontSize: '36px',
                                            fontWeight: 400,
                                            lineHeight: '39.74px',
                                            textAlign: 'left',
                                        }}
                                    >
                                        Links
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Sofia Sans, sans-serif',
                                            fontSize: '18px',
                                            fontWeight: 400,
                                            lineHeight: '21.6px',
                                            textAlign: 'left',
                                            color: 'rgba(108, 105, 85, 1)',
                                            marginTop: '20px'
                                        }}
                                    >
                                        <Link href="/#home" color="inherit" underline="none">Home</Link>
                                        <br />
                                        <br />
                                        <Link href="/about" color="inherit" underline="none" sx={{ marginTop: '30px' }}>About Us</Link>
                                    </Typography>
                                </Stack>

                                <Button
                                    variant="outlined"
                                    sx={{
                                        backgroundColor: 'rgba(171, 175, 140, 1)',
                                        color: 'rgba(65, 69, 45, 1)',
                                        marginTop: '20px',
                                        padding: '10px 20px',
                                        borderRadius: '20px',
                                        maxWidth: 'fit-content',
                                        border: '1px solid rgba(65, 69, 45, 1)',
                                        '&:hover': {
                                            border: '1px solid rgba(65, 69, 45, 1)',
                                        },
                                    }}
                                    onClick={handleLavishaRedirect}
                                >
                                    LAVISHA MAIN WEBSITE
                                </Button>
                            </Grid>
                        </Grid>

                        <Box sx={{ marginTop: '40px', textAlign: 'center' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                <img
                                    src={imgLogo}
                                    alt="logo"
                                    loading="lazy"
                                    style={{ objectFit: "contain", width: "250px", marginRight: '20px' }}
                                />
                                <Stack direction={'row'}>
                                    <Link href="https://www.facebook.com/lbbmalaysia" color="inherit" sx={{ marginRight: '10px' }}>
                                        <SocialFb />
                                    </Link>
                                    <Link href="https://www.instagram.com/lbbmalaysia/" color="inherit">
                                        <SocialIg />
                                    </Link>
                                </Stack>
                            </Box>

                            {alertVisible && (
                                <Alert
                                    severity="success"
                                    icon={<CheckIcon fontSize="inherit" />}
                                    sx={{ marginTop: '20px' }}
                                >
                                    We will reach out to you soon!
                                </Alert>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            {/* Scroll Up Arrow */}
            <Box
                sx={{
                    position: 'absolute',
                    top: '20px',
                    right: '20px',
                    backgroundColor: '#AAB086',
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
                <ArrowUp />
            </Box>
        </Box>
    );
};
