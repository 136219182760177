import React, { useState } from 'react';
import { Modal, Box, Grid, Typography, IconButton, TextField, Button, CircularProgress } from '@mui/material';
import { ArrowForward, Close as CloseIcon } from '@mui/icons-material';
import modalImg from '../assets/Modal_image.png';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

interface JoinUsNowModalProps {
    open: boolean;
    handleClose: () => void;
    setOpen: (open: boolean) => void;
}

export const JoinUsNowModal: React.FC<JoinUsNowModalProps> = ({
    open,
    handleClose,
    setOpen,
}) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertVisible, setAlertVisible] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const closeModal = () => {
        setEmail('');
        handleClose();
        setError(false);
        setSubmitted(false);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setEmail(value);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setError(!emailRegex.test(value));
    };

    const handleSubmit = () => {
        setSubmitted(true); // Mark the form as submitted
        if (error) return; // Prevent submission if there is an error

        setLoading(true);
        const formData = new FormData();
        formData.append('email', email);

        fetch("https://script.google.com/macros/s/AKfycbzWic5SfdosPzd5_Uid8FaU8lxjxGpFKkbWEmY9EO76rk7HsQtJMR0JZPv5Gz7DIoN2Bg/exec", {
            method: 'POST',
            body: formData,
        })
            .then(res => res.json())
            .then(data => {
                setLoading(false);
                setAlertVisible(true);
                setTimeout(() => {
                    setAlertVisible(false);
                    closeModal();
                }, 2000); // Wait 2 seconds before closing the modal
            })
            .catch(err => {
                setLoading(false);
                console.log(err);
            });
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    width: { xs: '80%', md: '60%' },
                    bgcolor: 'white',
                    borderRadius: '8px',
                    boxShadow: 24,
                    overflow: 'visible',
                    position: 'relative',
                }}
            >
                {alertVisible && (
                    <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                        We will reach out to you soon!
                    </Alert>
                )}
                <Grid container sx={{ padding: '30px' }}>
                    <Grid item xs={12} sm={6} lg={6} sx={{ textAlign: { xs: 'center', lg: 'left' } }}>
                        <Box
                            sx={{
                                height: { xs: '200px', sm: '100%' },
                                width: '100%',
                                backgroundImage: `url(${modalImg})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                borderRadius: '12px',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%',
                                padding: '20px',
                            }}
                        >
                            <Box sx={{ textAlign: { xs: 'center', lg: 'left' } }}>
                                <Typography
                                    variant="h2"
                                    sx={{
                                        fontFamily: 'Forum, serif',
                                        fontSize: {
                                            xs: '24px',
                                            sm: '36px',
                                            md: '48px',
                                            lg: '56px',
                                        },
                                        fontWeight: 400,
                                        lineHeight: {
                                            xs: '30px',
                                            sm: '40px',
                                            md: '50px',
                                            lg: '60px',
                                        },
                                    }}
                                >
                                    Be the first to know!
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Sofia Sans, sans-serif',
                                        fontSize: {
                                            xs: '12px',
                                            sm: '14px',
                                            md: '16px',
                                            lg: '18px',
                                        },
                                        fontWeight: 400,
                                        lineHeight: {
                                            xs: '16px',
                                            sm: '18px',
                                            md: '21.6px',
                                        },
                                        marginTop: '20px',
                                    }}
                                >
                                    Le Mielé transcends the realm of athleisure to become a statement of conscious luxury. We are not just about evolving fashion trends, but about weaving wellness into every fiber of our garments.
                                </Typography>
                            </Box>
                            <Box sx={{ textAlign: { xs: 'center', lg: 'left' } }}>
                                <Typography
                                    sx={{
                                        fontFamily: 'Sofia Sans, sans-serif',
                                        fontSize: {
                                            xs: '18px',
                                            sm: '20px',
                                            md: '25px',
                                        },
                                        fontWeight: 300,
                                        lineHeight: {
                                            xs: '22px',
                                            sm: '24px',
                                            md: '30px',
                                        },
                                        marginTop: '20px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Join The Wait
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', lg: 'flex-start' }, marginTop: '20px' }}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Sofia Sans, sans-serif',
                                            fontSize: {
                                                xs: '10px',
                                                sm: '12px',
                                                md: '14px',
                                                lg: '15px',
                                            },
                                            fontWeight: 400,
                                            lineHeight: {
                                                xs: '12px',
                                                sm: '14px',
                                                md: '16px',
                                                lg: '18px',
                                            },
                                            flexGrow: 1,
                                        }}
                                    >
                                        YOUR EMAIL ADDRESS*
                                    </Typography>
                                    <IconButton
                                        onClick={() => handleSubmit()}
                                        disabled={error || loading}
                                        sx={{
                                            color: error || loading ? '#D3D3D3' : '#000',
                                        }}
                                    >
                                        {loading ? <CircularProgress size={24} /> : <ArrowForward sx={{ marginLeft: '10px' }} />}
                                    </IconButton>
                                </Box>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    value={email}
                                    onChange={handleEmailChange}
                                    error={error && submitted} // Show error text only if submitted
                                    helperText={error && submitted ? 'Oops, this email doesn’t look right.' : ''}
                                    sx={{
                                        fontFamily: 'Sofia Sans, sans-serif',
                                        fontSize: {
                                            xs: '12px',
                                            sm: '14px',
                                            md: '15px',
                                        },
                                        fontWeight: 400,
                                        lineHeight: {
                                            xs: '14px',
                                            sm: '16px',
                                            md: '18px',
                                        },
                                        marginBottom: '10px',
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            fontFamily: 'Sofia Sans, sans-serif',
                                            fontSize: {
                                                xs: '10px',
                                                sm: '12px',
                                                md: '14px',
                                            },
                                            fontWeight: 400,
                                            lineHeight: {
                                                xs: '12px',
                                                sm: '14px',
                                                md: '16px',
                                            },
                                            textAlign: 'left',
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Button
                    onClick={closeModal}
                    sx={{
                        position: 'absolute',
                        top: '-30px',
                        right: '-30px',
                        backgroundColor: '#abaf8c',
                        color: 'white',
                        borderRadius: {
                            xs: '35px',
                            sm: '35px',
                            md: '25px',
                            lg: '40px',
                        },
                        width: {
                            xs: '20px',
                            sm: '50px',
                            md: '50px',
                            lg: '80px',
                        },
                        height: {
                            xs: '60px',
                            sm: '60px',
                            md: '50px',
                            lg: '80px',
                        },
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: 2,
                        '&:hover': {
                            backgroundColor: '#8d9275',
                        },
                    }}
                >
                    <CloseIcon />
                </Button>
            </Box>
        </Modal>
    );
};
