import React, { useEffect, useState } from 'react';
import { JoinUsNowModal } from '../components/JoinUsNowModal';
import { Page1 } from './aboutScreens/Page1';
import { Footer } from '../components/Footer';
import { Page2 } from './aboutScreens/Page2';


const AboutPage: React.FC = () => {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };



    const [modalTriggered, setModalTriggered] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            // Convert 150vh to pixels
            const scrollThreshold = window.innerHeight * 1;

            if (window.scrollY > scrollThreshold && !modalTriggered) {
                setOpen(true);
                setModalTriggered(true); // Prevent further triggers
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [modalTriggered]);




    return (
        <>
            <Page1 />
            <Page2 setOpen={setOpen} />

            <Footer />
            <JoinUsNowModal
                open={open}
                handleClose={handleClose}
                setOpen={setOpen}
            />


        </>
    );
};

export default AboutPage;
