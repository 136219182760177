import { Box, Typography } from '@mui/material';
import yogaImg from '../../assets/Hero_USP.webp';

export const Page3 = () => {
    interface BoxData {
        title: string;
        description: string;
        tagLine: string;
    }

    const boxesData: BoxData[] = [
        {
            title: 'Context Settings',
            description: 'Our pants embody a trendy athleisure style suitable for various occasions. Embrace a fashionable and functional lifestyle.',
            tagLine: 'Any place',
        },
        {
            title: 'Forget about size struggles',
            description: 'Our apparel is super stretchy, which makes for versatile sizings. With 3 versatile sizes, forget about the size struggles. ',
            tagLine: 'FREE SIZE',
        },
        {
            title: 'Material Durability',
            description: 'Designed for your convenience, our garments are washing machine and dryer-friendly, ensuring worry-free cleaning.',
            tagLine: 'EASY CARE',
        }
    ];
    return (
        <Box
            id="description"
            sx={{
                position: "relative",
                width: "100%",
                height: { xs: '180vh', sm: '100vh', md: '150vh' },
                backgroundColor: "#EEEFE8",
                overflowX: "clip",
                "& img": {
                    position: "sticky",
                    top: { xs: '100px', md: '10px' },
                    left: 0,
                    height: "auto",
                    objectFit: "contain",
                    maxWidth: { md: "100%" },
                    transform: { xs: 'scale(2.0) translateX(20%)', md: 'scale(1)' }, // Scale up on mobile view
                    minWidth: {
                        xl: '100%'
                    }
                },
            }}
        >
            <img
                loading="lazy"
                src={yogaImg}
                alt="Hero Yoga"
                style={{
                    maxWidth: '100%',
                    height: 'auto',

                }}

            />
            <Box
                style={{
                    position: 'absolute',
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                }}
                sx={{
                    top: { xs: '20px', md: '200px' },
                    paddingRight: { xs: '20px', md: '50px', lg: '100px' }, // 50px on small screens, 10px on medium screens and up
                    paddingLeft: { xs: '20px', md: '50px' }, // 50px on small screens, 10px on medium screens and up
                    alignItems: {
                        md: 'flex-end', // Default for extra-small screens
                        xs: 'center', // Center on medium screens and above
                    },
                }}
            >
                {boxesData.map((box, index) => (
                    <div style={{
                        height: '300px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        position: 'sticky',
                        top: 200,
                    }}
                    >
                        <Box
                            key={index}
                            sx={{
                                maxWidth: '600px',
                                maxHeight: '300px',
                                borderRadius: '30px',
                                border: '1px solid transparent',
                                opacity: 1,
                                background: '#fff',
                                padding: '40px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                position: 'sticky',
                                top: 0,
                                zIndex: 10 - index // Adjust z-index for stacking
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Sofia Sans, sans-serif',
                                    fontSize: '25px',
                                    fontWeight: 300,
                                    lineHeight: '30px',
                                    textAlign: 'left',
                                    color: '#2C2C2C',
                                }}
                            >
                                {box.title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Sofia Sans, sans-serif',
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    lineHeight: '21.6px',
                                    textAlign: 'left',
                                    color: '#6C6955',
                                    marginTop: '5px'
                                }}
                            >
                                {box.description}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Sofia Sans, sans-serif',
                                    fontSize: '18px',
                                    fontWeight: 600,
                                    lineHeight: '21.6px',
                                    letterSpacing: '0.02em',
                                    textAlign: 'left',
                                    paddingTop: '30px',
                                    color: '#6C6955'
                                }}
                            >
                                {box.tagLine}
                            </Typography>
                        </Box>
                    </div>

                ))}
            </Box>

        </Box>
    );
};