import { Box, Grid, Typography } from '@mui/material';
import heroImg from '../../assets/Hero_Home.webp';

export const Page1 = (props: { setOpen: (arg0: boolean) => void; }) => {
    return (
        <Box
            id="home"
            sx={{
                position: "relative",
                height: "100vh",
                overflow: "hidden",
                "& img": {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%",
                    width: { xs: 'none', md: '100%' },
                    objectFit: "cover",
                    transform: { xs: 'translateX(-45%)', md: 'none' }, // Scale up on mobile view
                },
            }}
        >
            <img
                loading="lazy"
                src={heroImg}
                alt="Conscious luxury background"
            />
            <Grid
                container
                sx={{
                    position: 'absolute',
                    top: '20%',
                    left: {
                        xs: '20px',
                        sm: '30px',
                        md: '50px',
                    },
                    padding: '0 20px',
                }}
            >
                <Grid item xs={7} md={8} lg={7}>
                    <Typography
                        sx={{
                            color: '#41452D',
                            fontFamily: 'Forum, serif',
                            fontSize: {
                                xs: '40px',
                                sm: '60px',
                                md: '80px',
                            },
                            lineHeight: {
                                xs: '48px',
                                sm: '60px',
                                md: '72px',
                            },
                            fontWeight: 400,
                            textAlign: 'left',
                        }}
                    >
                        Embracing Conscious Luxury.
                    </Typography>
                    <Typography
                        sx={{
                            color: '#41452D',
                            fontFamily: 'Sofia Sans, sans-serif',
                            fontSize: {
                                xs: '20px',
                                sm: '25px',
                                md: '30px',
                            },
                            fontWeight: 300,
                            lineHeight: {
                                xs: '24px',
                                sm: '28px',
                                md: '32.31px',
                            },
                            textAlign: 'left',
                            marginTop: '20px',
                        }}
                    >
                        Garments that enhance both physical performance and mindful living.
                    </Typography>
                    <Box sx={{ cursor: 'pointer' }}>
                        <Typography
                            sx={{
                                fontFamily: 'Sofia Sans, sans-serif',
                                fontSize: '18px',
                                fontWeight: 400,
                                textAlign: 'left',
                                marginTop: '50px',
                                textDecoration: 'underline',
                                color: '#41452D',
                            }}
                            onClick={() => {
                                props.setOpen(true);
                            }}
                        >
                            JOIN THE WAIT
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};