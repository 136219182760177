import VeneFabric from '../../assets/VeneFabric.webp';
import { ReactComponent as EcoSage } from '../../assets/Eco_Sage.svg';
import { ReactComponent as EcoWhite } from '../../assets/Eco_White.svg';
import { Box, Typography, Grid } from '@mui/material';

export const Page5 = () => {
    return (
        <Box
            sx={{
                backgroundColor: "#EEEFE8",
                width: "100%",
                display: "flex",
                paddingTop: '100px',
                paddingBottom: '100px',
                justifyContent: 'center',
            }}
        >
            <Grid container spacing={3} maxWidth={'90%'} sx={{ backgroundColor: "#EEEFE8" }}>
                <Grid item xs={12} md={5}>
                    <Box
                        sx={{
                            position: 'relative',
                            borderRadius: '30px',
                            overflow: 'hidden',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={VeneFabric} // Replace with the image source
                            alt="Vene Fabric"
                            style={{
                                width: '100%', // Ensure the image covers the container width
                                height: 'auto',
                                borderRadius: '30px',
                                objectFit: 'cover',
                            }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: '0',
                                left: '50%',
                                transform: 'translate(-50%, -10%)', // Center the text
                                textAlign: 'center',
                                color: '#FFF',
                                zIndex: 1,
                                padding: '10px',
                                width: '100%',
                                boxSizing: 'border-box',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Forum, serif',
                                    fontSize: '56px',
                                    fontWeight: 400,
                                    lineHeight: '60px',
                                    paddingBottom: '10px',
                                }}
                            >
                                Vene Fabric
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Sofia Sans, sans-serif',
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    lineHeight: '21.6px',
                                }}
                            >
                                Our hero fabric made in Korea is OEKO-TEX Standard 100 certified, retaining its benefits and intelligent properties even after many washes.
                            </Typography>
                        </Box>
                        <Box
                            position="absolute"
                            top={0}
                            width="100%"
                            height="100%"
                            sx={{
                                background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%)',
                                borderRadius: '30px',
                                zIndex: 0, // Ensure gradient is behind text
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={7} display="flex" flexDirection="column" sx={{ height: "100%" }}>
                    <Grid container spacing={2} sx={{ flexGrow: { xs: 0, md: 0, lg: 1 } }}>
                        {[
                            { title: "Eco-Friendly Fabric Material", description: "No harmful substances present and sustainably produced without compromising quality" },
                            { title: "DryTech & antivirus effect", description: "Dry tech with antivirus properties for ultimate comfort and protection" },
                            { title: "Nylon 6.6", description: "Durable fabric that stretches well and consistently returns to its original shape after prolonged use." },
                            { title: "Promotes faster muscle recovery", description: "Promotes faster muscle recovery and reduces fatigue, enhancing overall performance and comfort." },
                            { title: "Cellulite suppression effect", description: "Improves skin elasticity & reduces appearance of cellulites for enhanced performance & endurance." },
                            { title: "Improves blood circulation", description: "Activates lymphatic system and blood circulation by absorbing heat and regulating skin temperature." },
                            { title: "SPF 50+ UV protection & non see-through", description: "Fabric is opaque that ensures both sun safety and confident coverage." },
                            {
                                title: "Lightweight & durable", description: "Offers comfort and long-lasting wear and is perfect for any activity."
                            }
                        ].map((item, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                                <Box
                                    sx={{
                                        position: 'relative',
                                        backgroundColor: "#FFFFFF",
                                        borderRadius: '30px',
                                        padding: '20px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        justifyContent: 'space-around',
                                        height: '100%',
                                        flexGrow: 1,
                                        minHeight: '200px',
                                        overflow: 'hidden',
                                        '&:hover .overlay': {
                                            transform: 'translateX(0)', // Move overlay into view
                                        },
                                    }}
                                >
                                    <EcoSage
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            zIndex: 2,
                                        }}
                                    />

                                    <Typography
                                        sx={{
                                            fontFamily: 'Sofia Sans, sans-serif',
                                            fontSize: '18px',
                                            fontWeight: 400,
                                            lineHeight: '21.6px',
                                            textAlign: 'left',
                                            zIndex: 1,
                                        }}
                                    >
                                        {item.title}
                                    </Typography>

                                    {/* Overlay element */}
                                    <Box
                                        className="overlay"
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: 'rgba(171, 175, 140, 1)',
                                            color: '#FFFFFF',
                                            display: 'flex',
                                            transform: 'translateX(-105%)', // Start off-screen to the left
                                            transition: 'transform 0.3s ease',
                                            zIndex: 2,
                                            padding: '30px',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            justifyContent: 'space-around',
                                        }}
                                    >

                                        <EcoWhite
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                                zIndex: 2,
                                            }}
                                        />


                                        <Typography
                                            sx={{
                                                fontFamily: 'Sofia Sans, sans-serif',
                                                fontSize: '18px',
                                                fontWeight: 400,
                                                lineHeight: '21.6px',
                                                textAlign: 'left',
                                            }}
                                        >
                                            {item.description}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Box>

    );
};