import Scan from '../../assets/scan.png';
import FaceScan from '../../assets/face_scan.png';
import logoBackgrond from '../../assets/LargeLogoFooter.svg';
import { ReactComponent as LeafForest } from '../../assets/Leaf_Forest.svg';
import { ReactComponent as LeafSage } from '../../assets/Leaf_Sage.svg';
import { Box, Typography, Grid, Link } from '@mui/material';

export const Page6 = () => {
    return (
        <Box
            id='benefits'
            sx={{
                position: 'relative',
                backgroundColor: "#EEEFE8",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: '100px',
                height: {
                    xl: '200vh',
                    md: '180vh',
                    sm: '230vh',
                    xs: '300vh',
                },
            }}
        >
            <Box
                sx={{
                    position: 'sticky',
                    top: '100px',
                    left: '100px',
                    width: { xs: '80%', md: '100%' }, // Adjust width for different screen sizes
                    height: '50%', // Ensure height is set to cover viewport height
                    backgroundImage: `url(${logoBackgrond})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right',
                    filter: 'blur(3px)', // Optional: Apply blur if needed
                    zIndex: 1,
                }}
            />
            <Box sx={{ position: 'absolute', top: '100px', maxWidth: '90%', alignItems: 'center', display: 'flex', flexDirection: 'column' }} zIndex={2}>
                <Grid container spacing={3} maxWidth={'90%'} height={'100%'} position={'relative'}>
                    <Grid item xs={12} md={5} sx={{ display: 'flex', alignItems: 'center', height: 'auto' }}>
                        {/* Left side content */}
                        <Box sx={{ position: 'relative', borderRadius: '20px', width: '100%' }}>
                            <img
                                src={Scan} // Replace with your image URL
                                alt="Left side content"
                                style={{ width: '100%', height: 'auto', borderRadius: '20px' }}
                            />
                            <LeafForest
                                style={{
                                    position: 'absolute',
                                    top: '-50px', // Adjust as needed
                                    left: '-50px', // Adjust as needed
                                    zIndex: 2, // Ensure the button is above the image
                                }}
                            />
                        </Box>

                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: 'auto'
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Sofia Sans, sans-serif',
                                fontSize: '18px',
                                fontWeight: 600,
                                lineHeight: '21.6px',
                                letterSpacing: '0.02em',
                                color: 'rgba(65, 69, 45, 1)',
                                paddingBottom: '20px'
                            }}
                        >
                            {`[THE BENEFITS OF]`}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Forum, serif',
                                fontSize: '56px',
                                fontWeight: 400,
                                lineHeight: '60px',
                                color: 'rgba(65, 69, 45, 1)',
                                paddingBottom: '20px'
                            }}
                        >
                            Improved Blood Oxygen
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Sofia Sans, sans-serif',
                                fontSize: '18px',
                                fontWeight: 400,
                                lineHeight: '21.6px',
                                color: 'rgba(65, 69, 45, 1)',
                            }}
                        >
                            The advanced Vene fabric used in our garments enhances blood circulation and promotes better oxygen delivery to muscles, helping to reduce fatigue and improve overall performance. By increasing blood flow, our athleisure wear supports faster recovery and elevates your workout experience, ensuring that you perform at your best while feeling refreshed and revitalized.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3} maxWidth={'90%'} height={'100%'} marginTop={'50px'}>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: 'auto',
                            order: { md: 1, xs: 2 } // Change order for large screens
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Sofia Sans, sans-serif',
                                fontSize: '18px',
                                fontWeight: 600,
                                lineHeight: '21.6px',
                                letterSpacing: '0.02em',
                                color: 'rgba(65, 69, 45, 1)',
                                paddingBottom: '20px'
                            }}
                        >
                            {`[BODY ANALYSIS BY L'ARC]`}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Forum, serif',
                                fontSize: '56px',
                                fontWeight: 400,
                                lineHeight: '60px',
                                color: 'rgba(65, 69, 45, 1)',
                                paddingBottom: '20px'
                            }}
                        >
                            Tailored for your body
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Sofia Sans, sans-serif',
                                fontSize: '18px',
                                fontWeight: 400,
                                lineHeight: '21.6px',
                                textAlign: 'left',
                                color: 'rgba(65, 69, 45, 1)',
                            }}
                        >
                            L’arc Spa is a brand under Lavisha Group that combines personalized wellness and high-tech innovations to offer a transformative spa experience. Discover the art of personalized beauty and wellness today.
                        </Typography>
                        <Link
                            href="https://larc.lavishamsia.com"
                            sx={{
                                textDecoration: "none",
                                color: 'rgba(65, 69, 45, 1)',
                                '&:hover': {
                                    color: "#abaf8c",
                                    textDecoration: "underline",
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Sofia Sans, sans-serif',
                                    fontWeight: 400,
                                    fontSize: '18px',
                                    lineHeight: '21.88px',
                                    textAlign: 'left',
                                    textDecoration: "underline", // Adds underline
                                    cursor: "pointer",
                                    marginTop: '30px',
                                    zIndex: 10,
                                }}
                            >
                                DISCOVER L’ARC
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={5}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: 'auto',
                            order: { md: 2, xs: 1 } // Change order for large screens
                        }}
                    >
                        <Box sx={{ position: 'relative', borderRadius: '20px', width: '100%' }}>
                            <img
                                src={FaceScan} // Replace with your image URL
                                alt="Right side content"
                                style={{ width: '100%', height: 'auto', borderRadius: '20px' }}
                            />
                            <LeafSage
                                style={{
                                    position: 'absolute',
                                    bottom: '-40px', // Adjust as needed
                                    right: '-40px', // Adjust as needed
                                    zIndex: 2, // Ensure the button is above the image
                                }}
                            />
                        </Box>

                    </Grid>
                </Grid>
            </Box>

        </Box>

    );
};