import { Box, Grid, Typography } from '@mui/material';
import logoBackgrond from '../../assets/LargeLogoFooter.svg';
import productImage3 from '../../assets/Products_03.jpg';
import productImage1 from '../../assets/Products_01.jpg';
import { ReactComponent as ArcLine } from '../../assets/ArcLine.svg';

export const Page1 = () => {
    return (
        <Box
            id='benefits'
            sx={{
                position: 'relative',
                backgroundColor: "#EEEFE8",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: '100px',
                paddingTop: '200px',
                // height: '250vh'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '550px',
                    width: '50%', // Adjust width for different screen sizes
                    height: '40%', // Ensure height is set to cover viewport height
                    backgroundImage: `url(${logoBackgrond})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'contain',
                    filter: 'blur(1px)', // Optional: Apply blur if needed
                    maskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)',
                    WebkitMaskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)',
                }}
            />

            <Box sx={{ maxWidth: '90%', alignItems: 'center', display: 'flex', flexDirection: 'column' }} zIndex={2}>
                <Typography
                    sx={{
                        fontFamily: 'Sofia Sans, sans-serif',
                        fontSize: '18px',
                        fontWeight: 600,
                        lineHeight: '21.6px',
                        letterSpacing: '0.02em',
                        textAlign: 'center',
                        color: "rgba(108, 105, 85, 1)",
                    }}
                >
                    {'[OUR STORY]'}
                </Typography>
                <Typography
                    variant="h2"
                    sx={{
                        fontFamily: 'Forum, serif',
                        fontSize: '56px',
                        fontWeight: 400,
                        lineHeight: '60px',
                        textAlign: 'center',
                    }}
                >
                    Where Fashion Meets Wellness,
                    <br />
                    Elevating Athleisure to an Art Form
                </Typography>
                {/* <Typography
                    sx={{
                        fontFamily: 'Sofia Sans, sans-serif',
                        fontSize: '18px',
                        fontWeight: 400,
                        lineHeight: '21.6px',
                        textAlign: 'center',
                        color: "rgba(65, 69, 45, 1)",
                        marginTop: '20px',
                        padding: '20px'
                    }}
                >
                    Le Mielé transcends the realm of athleisure to become a statement of conscious luxury. We are not just
                    <br />
                    about evolving fashion trends, but about weaving wellness into every fiber of our garments.
                </Typography> */}
                <Grid container spacing={3} maxWidth={'100%'} height={'100%'} sx={{
                    marginTop: {
                        xs: '10px',
                        md: '200px',
                    }
                }

                }>
                    <Grid item xs={12} sm={9} sx={{ display: 'flex', alignItems: 'center', height: 'auto' }} />
                    <Grid
                        item
                        xs={12}
                        sm={3}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: 'auto'

                        }}
                    >
                        <Box
                            sx={{
                                position: 'relative',
                                borderRadius: '30px',
                                overflow: 'hidden',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={productImage3}
                                alt="Product 1"
                                style={{
                                    width: '-webkit-fill-available',
                                    height: '-webkit-fill-available',
                                    objectFit: 'cover',
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3} maxWidth={'100%'} height={'100%'} marginTop={'50px'}>
                    <Grid
                        item
                        xs={12}
                        sm={5}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: 'auto',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Forum, serif',
                                fontSize: {
                                    xs: '36px', // Adjust size for small screens if needed
                                    sm: '48px', // Default size
                                },
                                fontWeight: 400,
                                lineHeight: {
                                    xs: '40px', // Adjust line height for small screens if needed
                                    sm: '52.5px', // Default line height
                                },
                                textAlign: {
                                    xs: 'center', // Center text on small screens if needed
                                    sm: 'left', // Default alignment
                                },
                            }}
                            color={'rgba(44, 44, 44, 1)'}
                        >
                            Weaving together conscious luxury and holistic wellness and  inspiring a healthier and more mindful lifestyle.
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Sofia Sans, sans-serif',
                                fontSize: {
                                    xs: '14px', // Adjust size for small screens if needed

                                    sm: '18px', // Default size
                                },
                                fontWeight: 400,
                                lineHeight: {
                                    xs: '16px', // Adjust line height for small screens if needed

                                    sm: '21.6px', // Default line height
                                },
                                textAlign: {
                                    xs: 'center', // Center text on small screens if needed

                                    sm: 'left', // Default alignment
                                },
                                marginTop: '20px'
                            }}
                            color='rgba(108, 105, 85, 1)'
                        >
                            Le Mielé transcends the realm of athleisure to become a statement of conscious luxury. We are not just about evolving fashion trends, but about weaving wellness into every fiber of our garments.
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Sofia Sans, sans-serif',
                                fontSize: '18px',
                                fontWeight: 600,
                                lineHeight: '21.6px',
                                letterSpacing: '0.02em',
                                textAlign: 'left',
                                color: 'rgba(108, 105, 85, 1)',

                                marginTop: '30px',
                            }}
                        >
                            OUR VISION
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={0}
                        sm={6}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: 'auto',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'relative',
                                borderRadius: '30px',
                                overflow: 'hidden',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignSelf: 'flex-end',
                                display: {
                                    xs: 'none', sm: 'flex'
                                }
                            }}
                        >
                            <ArcLine
                                style={{
                                    width: '-webkit-fill-available',
                                    height: '-webkit-fill-available',
                                    objectFit: 'cover',
                                }}
                            />
                        </Box>

                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={3}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: 'auto',
                            marginTop: {
                                xs: '20px',
                                md: '200px'
                            }
                        }}
                    >
                        <img
                            src={productImage1}
                            alt="Product 1"
                            style={{
                                width: '-webkit-fill-available',
                                height: 'auto',
                                maxHeight: '400px',
                                objectFit: 'cover',
                                borderRadius: '20px',
                                alignItems: 'flex-end',

                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: 'auto',
                        }}
                    />
                    <Grid
                        item
                        xs={12}
                        sm={5}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            height: 'auto',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Forum, serif',
                                fontSize: {
                                    xs: '36px', // Adjust size for small screens if needed

                                    sm: '48px', // Default size
                                },
                                fontWeight: 400,
                                lineHeight: {
                                    xs: '40px', // Adjust line height for small screens if needed

                                    sm: '52.5px', // Default line height
                                },
                                textAlign: {
                                    xs: 'center', // Center text on small screens if needed

                                    sm: 'left', // Default alignment
                                },
                            }}
                            color={'rgba(44, 44, 44, 1)'}
                        >
                            Creating innovative athleisure wear that enhances physical performance and promotes well-being.
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Sofia Sans, sans-serif',
                                fontSize: {
                                    xs: '14px', // Adjust size for small screens if needed

                                    sm: '18px', // Default size
                                },
                                fontWeight: 400,
                                lineHeight: {
                                    xs: '16px', // Adjust line height for small screens if needed

                                    sm: '21.6px', // Default line height
                                },
                                textAlign: {
                                    xs: 'center', // Center text on small screens if needed

                                    sm: 'left', // Default alignment
                                },
                                marginTop: '20px'
                            }}
                            color='rgba(108, 105, 85, 1)'
                        >
                            We aim to provide stylish, high-quality garments that support and elevate our customers' active lifestyles.
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Sofia Sans, sans-serif',
                                fontSize: '18px',
                                fontWeight: 600,
                                lineHeight: '21.6px',
                                letterSpacing: '0.02em',
                                textAlign: 'left',
                                color: 'rgba(108, 105, 85, 1)',

                                marginTop: '30px',
                            }}
                        >
                            OUR MISSION
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

        </Box >

    );
};