import { Box, Backdrop, Grid, Typography } from '@mui/material';
import storyImg from '../../assets/WhereWeStand.svg';
import { MouseEventHandler, useState } from 'react';

export const Page2 = (props: { setOpen: (arg0: boolean) => void; }) => {

    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };
    return (
        <Box
            id="story"
        >
            <Backdrop
                sx={{
                    position: 'absolute',
                    zIndex: 1,
                    height: '300vh'
                }}
                open={isHover}
            >
            </Backdrop>
            <Box
                sx={{
                    backgroundColor: "#EEEFE8", // Background color for the content
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "20px", // Adjust padding as needed
                }}
            >
                <Grid
                    container
                    spacing={20}
                    sx={{
                        paddingTop: '200px',
                        paddingBottom: '200px'
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <>
                            <Box

                                sx={{ position: 'relative', display: 'inline-block' }}
                                zIndex={2}
                            >
                                <img
                                    src={storyImg}
                                    alt="Illustration"
                                    style={{ maxWidth: '100%', height: 'auto', display: 'block' }}
                                />
                                <Box
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: '40%',  // Adjust the size of the hoverable area
                                        height: '100%',
                                        cursor: 'pointer',
                                        zIndex: 3,
                                    }}
                                />
                                {isHover && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '90%',
                                            left: '50%',
                                            transform: 'translate(-50%, -100%)', // Positioning the tooltip above the center
                                            bgcolor: '#fff', // Background color of the tooltip
                                            borderRadius: '8px', // Rounded corners
                                            padding: '10px 20px',
                                            textAlign: 'center',
                                            zIndex: 3,
                                            '&::before': {
                                                content: '""',
                                                position: 'absolute',
                                                top: '-10px', // Position the triangle at the top of the tooltip
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                width: 0,
                                                height: 0,
                                                borderLeft: '10px solid transparent',
                                                borderRight: '10px solid transparent',
                                                borderBottom: '10px solid #FFFFFF', // Match the tooltip background color
                                            },
                                        }}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: "Sofia Sans, sans-serif",
                                                fontSize: "18px",
                                                fontWeight: 600,
                                                lineHeight: "21.6px",
                                                letterSpacing: "0.02em",
                                                color: "#000",
                                            }}
                                        >
                                            WHERE WE<br /> STAND
                                        </Typography>
                                    </Box>
                                )}
                            </Box>

                        </>
                        {/* <img src={storyImg} alt="Illustration" style={{ maxWidth: '100%', height: 'auto' }} /> */}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={5}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            paddingLeft: { xs: "0", md: "40px" },
                            paddingRight: { xs: "0", md: "40px" },
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: "Sofia Sans, sans-serif",
                                fontSize: {
                                    xs: "16px",
                                    sm: "18px",
                                    md: "18px",
                                },
                                fontWeight: 600,
                                lineHeight: "21.6px",
                                letterSpacing: "0.02em",
                                textAlign: "left",
                                color: '#6C6955',
                                paddingBottom: '20px'
                            }}
                        >
                            {'[OUR STORY]'}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: "Forum, serif",
                                fontSize: {
                                    xs: "40px",
                                    sm: "48px",
                                    md: "56px",
                                },
                                fontWeight: 400,
                                lineHeight: "60px",
                                textAlign: "left",
                                color: '#2C2C2C',
                                paddingBottom: '20px'
                            }}
                        >
                            Where Fashion Meets Wellness, Elevating Athleisure to an Art Form
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: "Sofia Sans, sans-serif",
                                fontSize: {
                                    xs: "16px",
                                    sm: "17px",
                                    md: "18px",
                                },
                                fontWeight: 400,
                                lineHeight: "21.6px",
                                textAlign: "left",
                                color: '#6C6955',
                            }}
                        >
                            Le Mielé transcends the realm of athleisure to become a statement of conscious luxury. We are not just about evolving fashion trends, but about weaving wellness into every fiber of our garments.
                        </Typography>
                        <Box sx={{ cursor: 'pointer' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Sofia Sans, sans-serif',
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    textAlign: 'left',
                                    marginTop: '50px',
                                    textDecoration: 'underline',
                                    color: '##41452D',
                                }}
                                onClick={() => {
                                    props.setOpen(true);
                                }}
                            >
                                READ MORE
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};